.mapa {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.map {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 2rem;
  min-width: 2rem !important;
  line-height: 2rem;
}
.map:before {
  content: '\00a0';
}
.mapa-autopista-entrada {
  background-image: url(src/assets/images/iconos-mapa/mapa_autopista_entrada.svg);
}
.mapa-autopista-salida {
  background-image: url(src/assets/images/iconos-mapa/mapa_autopista_salida.svg);
}
.mapa-cliente-direccion-entrega {
  background-image: url(src/assets/images/iconos-mapa/mapa_cliente_direccion_entrega.svg);
}
.mapa-gasolinera-autorizado {
  background-image: url(src/assets/images/iconos-mapa/mapa_gasolinera_autorizado.svg);
}
.mapa-gasolinera {
  background-image: url(src/assets/images/iconos-mapa/mapa_gasolinera.svg);
}
.mapa-parking-autorizado {
  background-image: url(src/assets/images/iconos-mapa/mapa_parking_autorizado.svg);
}
.mapa-parking {
  background-image: url(src/assets/images/iconos-mapa/mapa_parking.svg);
}
.mapa-taller-autorizado {
  background-image: url(src/assets/images/iconos-mapa/mapa_taller_autorizado.svg);
}
.mapa-taller {
  background-image: url(src/assets/images/iconos-mapa/mapa_taller.svg);
}
.mapa-zonas-cambios-trafico {
  background-image: url(src/assets/images/iconos-mapa/mapa_zonas_cambios_trafico.svg);
}
.mapa-aduanas {
  background-image: url(src/assets/images/iconos-mapa/mapa_aduanas.svg);
}
.mapa-almacenes {
  background-image: url(src/assets/images/iconos-mapa/mapa_almacenes.svg);
}
.mapa-deposito-mercancia {
  background-image: url(src/assets/images/iconos-mapa/mapa_deposito_mercancia.svg);
}
.mapa-embarques-entrada {
  background-image: url(src/assets/images/iconos-mapa/mapa_embarques_entrada.svg);
}
.mapa-embarques-salida {
  background-image: url(src/assets/images/iconos-mapa/mapa_embarques_salida.svg);
}
.mapa-fronteras {
  background-image: url(src/assets/images/iconos-mapa/mapa_fronteras.svg);
}
.mapa-punto-distribucion-mercancia {
  background-image: url(src/assets/images/iconos-mapa/mapa_punto_distribucion_mercancia.svg);
}
.mapa-terminales {
  background-image: url(src/assets/images/iconos-mapa/mapa_terminales.svg);
}
.mapa-vgm {
  background-image: url(src/assets/images/iconos-mapa/mapa_vgm.svg);
}
.mapa-desvio-ruta {
  background-image: url(src/assets/images/iconos-mapa/mapa_desvio_ruta.svg);
}
