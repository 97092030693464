.sys-veh-tipo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.sys-veh {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-veh:before {
  content: '\00a0';
}
.sys-veh-tipo-combustible-urea {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/urea.svg);
}
.sys-veh-tipo-combustible-gasolina {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/gasolina.svg);
}
.sys-veh-tipo-combustible-electrico {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/electrico.svg);
}
.sys-veh-tipo-combustible-hibrido {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/hibrido.svg);
}
.sys-veh-tipo-combustible-gnl {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/gnl.svg);
}
.sys-veh-tipo-combustible-gnc {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/gnc.svg);
}
.sys-veh-tipo-combustible-gas {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/gas.svg);
}
.sys-veh-tipo-combustible-gasoil-a {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/gasoil-a.svg);
}
.sys-veh-tipo-combustible-gasoil-b {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/gasoil-b.svg);
}
.sys-veh-tipo-combustible-hidrogeno {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/hidrogeno-verde.svg);
}
.sys-veh-tipo-tacografo-digital {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/tacografo-digital.svg);
}
.sys-veh-tipo-tacografo-analogico {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/tacografo-analogico.svg);
}
.sys-veh-tipo-tarjeta-definitiva {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/tarjeta-transporte-definitiva.svg);
}
.sys-veh-tipo-tarjeta-provisional {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/tarjeta-transporte-provisional.svg);
}
.sys-veh-tipo-trabajo-rueda-baja-vehiculo {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/trabajo-rueda-baja-vehiculo.svg);
}
.sys-veh-tipo-trabajo-rueda-baja {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/trabajo-rueda-baja.svg);
}
.sys-veh-tipo-trabajo-rueda-cruzar {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/trabajo-rueda-cruzar.svg);
}
.sys-veh-tipo-trabajo-rueda-almacen {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/trabajo-rueda-exportar-almacen.svg);
}
.sys-veh-tipo-trabajo-rueda-montar {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/trabajo-rueda-montar.svg);
}
.sys-veh-tipo-trabajo-rueda-rotar {
  background-image: url(src/assets/images/iconos-sys-veh-tipo/trabajo-rueda-rotar.svg);
}
