.sys-conv-modo-cobro {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-conv {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-conv:before {
  content: '\00a0';
}
.sys-conv-modo-cobro-cli-euros-km {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-cli/conv_modo_cobro_cli_euros_km.svg);
}
.sys-conv-modo-cobro-cli-importe-conv {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-cli/conv_modo_cobro_cli_importe_convenido.svg);
}
.sys-conv-modo-cobro-cli-precio-dia {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-cli/conv_modo_cobro_cli_precio_dia.svg);
}
.sys-conv-modo-cobro-cli-precio-mes {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-cli/conv_modo_cobro_cli_precio_mes.svg);
}
.sys-conv-modo-cobro-cli-precio-viaje {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-cli/conv_modo_cobro_cli_precio_viaje.svg);
}
.sys-conv-modo-cobro-cli-unidad-fact {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-cli/conv_modo_cobro_cli_unidad_facturacion.svg);
}
.sys-conv-modo-cobro-cli-tipo-km {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-cli/conv_modo_cobro_cli_tipo_km.svg);
}
