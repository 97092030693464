.sys-traf-tipo-cambios {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-cambios {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-cambios:before {
  content: '\00a0';
}

.sys-traf-tipo-cambios-expediente {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-cambios/asignar-expediente-completo.svg);
}
.sys-traf-tipo-cambios-tramo-actual-si {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-cambios/tramo-actual-si-unidad-transporte.svg);
}
.sys-traf-tipo-cambios-tramo-actual-no {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-cambios/tramo-actual-no-unidad-transporte.svg);
}
.sys-traf-tipo-cambios-tramo-actual-sig {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-cambios/tramo-actual-y-siguientes.svg);
}
