.sys-traf-eventos-tele {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-eventos-tele {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-eventos-tele:before {
  content: '\00a0';
}

.sys-traf-eventos-tele-actividades {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_actividades.svg);
}
.sys-traf-eventos-tele-alarma-fri {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_alarma_motor_frigorifico.svg);
}
.sys-traf-eventos-tele-des-android {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_descarga_android.svg);
}
.sys-traf-eventos-tele-ebs {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_ebs.svg);
}
.sys-traf-eventos-tele-empresa {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_empresa.svg);
}
.sys-traf-eventos-tele-nivel-dep {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_nivel_deposito.svg);
}
.sys-traf-eventos-tele-pois {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_pois.svg);
}
.sys-traf-eventos-tele-racc {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_racc.svg);
}
.sys-traf-eventos-tele-salida-ruta {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_salida_rutas.svg);
}
.sys-traf-eventos-tele-seg-equipo {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_seguimiento_equipo.svg);
}
.sys-traf-eventos-tele-seguridad {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_seguridad.svg);
}
.sys-traf-eventos-tele-sum-ener {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_suministro_energia.svg);
}
.sys-traf-eventos-tele-tacografo {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_tacografo.svg);
}
.sys-traf-eventos-tele-temperatura {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_temperatura.svg);
}
.sys-traf-eventos-tele-tpms {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_tpms.svg);
}
.sys-traf-eventos-tele-vat {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_vat_services.svg);
}
.sys-traf-eventos-tele-velocidad {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-telemetria/evento_velocidad.svg);
}
