.sys-recargo-cli {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-recargo {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-recargo:before {
  content: '\00a0';
}
.sys-recargo-cli-expediente {
  background-image: url(src/assets/images/sys-recargo-cli/porcentaje-imp-expediente.svg);
}
.sys-recargo-cli-recargo {
  background-image: url(src/assets/images/sys-recargo-cli/porcentaje-imp-recargo.svg);
}
.sys-recargo-cli-viaje {
  background-image: url(src/assets/images/sys-recargo-cli/porcentaje-imp-viaje.svg);
}
