.sys-imp-forma {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-imp {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-imp:before {
  content: '\00a0';
}

/* iconos sys-impuestos-forma-aplica-empresa */
.sys-imp-forma-origen-destino {
  background-image: url(src/assets/images/iconos-sys-impuestos-forma-aplica-empresa/por-origen-destino.svg);
}
.sys-imp-forma-tipo-operacion {
  background-image: url(src/assets/images/iconos-sys-impuestos-forma-aplica-empresa/por-tipo-operacion.svg);
}
/* iconos sys_impuestos_paises_tipo son los mismos que iconos sys-fact-tipo-cont */
.sys-imp-paises-tipo-propio {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipo-cont/trafico_nacional.svg);
}
.sys-imp-paises-tipo-tercero {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipo-cont/extracomunitario.svg);
}
.sys-imp-paises-tipo-comunitario {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipo-cont/comunitario.svg);
}
