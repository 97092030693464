.sys-traf-tipo-entrega {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-entrega {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-entrega:before {
  content: '\00a0';
}

.sys-traf-tipo-entrega-carga {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-carga.svg);
}
.sys-traf-tipo-entrega-carga-descarga {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-carga-descarga.svg);
}
.sys-traf-tipo-entrega-depositar {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-depositar.svg);
}
.sys-traf-tipo-entrega-descarga {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-descarga.svg);
}
.sys-traf-tipo-entrega-documentacion {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-documentacion.svg);
}
.sys-traf-tipo-entrega-movimiento-vacio {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-movimiento-vacio.svg);
}
.sys-traf-tipo-entrega-paso-aduanas {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-paso-aduanas.svg);
}
.sys-traf-tipo-entrega-puntos-embarque {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-puntos-embarque.svg);
}
.sys-traf-tipo-entrega-puntos-paso {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-puntos-paso.svg);
}
.sys-traf-tipo-entrega-trans-mercancia {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-transbordo-mercancias.svg);
}
.sys-traf-tipo-entrega-traslado-taller {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-traslado-taller.svg);
}
.sys-traf-tipo-entrega-punto-descanso {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-punto-descanso.svg);
}
.sys-traf-tipo-entrega-cambio-ruta {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-cambio-ruta.svg);
}
.sys-traf-tipo-entrega-pesaje {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-pesaje.svg);
}
.sys-traf-tipo-entrega-parking {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-parking.svg);
}
.sys-traf-tipo-entrega-lavado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-lavado.svg);
}
.sys-traf-tipo-entrega-desconsolidar {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-desconsolidar.svg);
}
.sys-traf-tipo-entrega-consolidar {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-consolidar.svg);
}
.sys-traf-tipo-entrega-term-cont {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-terminal-contenedores.svg);
}
.sys-traf-tipo-entrega-pif {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-pif.svg);
}
.sys-traf-tipo-entrega-laboratorio {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-laboratorio.svg);
}
