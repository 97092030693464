.sys-epi-tipo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-epi {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-epi:before {
  content: '\00a0';
}

/* iconos sys-epi-categorias */
.sys-epi-categorias-menor {
  background-image: url(src/assets/images/iconos-sys-epi/sys-epi-categoria-menor.svg);
}
.sys-epi-categorias-grave {
  background-image: url(src/assets/images/iconos-sys-epi/sys-epi-categoria-grave.svg);
}
.sys-epi-categorias-mayor {
  background-image: url(src/assets/images/iconos-sys-epi/sys-epi-categoria-mayor.svg);
}
/* iconos sys-epi-descontar-articulos */
.sys-epi-descontar-descontar {
  background-image: url(src/assets/images/iconos-sys-epi/sys-epi-descontar.svg);
}
.sys-epi-descontar-no-descontar {
  background-image: url(src/assets/images/iconos-sys-epi/sys-epi-no-descontar.svg);
}
/* iconos sys-epi-tipo */
.sys-epi-tipo-emple {
  background-image: url(src/assets/images/iconos-sys-epi/sys-epi-tipo-emple.svg);
}
.sys-epi-tipo-vehiculo {
  background-image: url(src/assets/images/iconos-sys-epi/sys-epi-tipo-vehiculo.svg);
}
