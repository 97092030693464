.fib {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  min-width: 1.33333333em;
  width: 1.33333333em;
  line-height: 1em;
}
.fi:before {
  content: '\00a0';
}
.fi.fis {
  width: 1em;
}
.fi-ad {
  background-image: url(src/assets/flags/ad.svg);
}
.fi-ae {
  background-image: url(src/assets/flags/ae.svg);
}
.fi-af {
  background-image: url(src/assets/flags/af.svg);
}
.fi-ag {
  background-image: url(src/assets/flags/ag.svg);
}
.fi-ai {
  background-image: url(src/assets/flags/ai.svg);
}
.fi-al {
  background-image: url(src/assets/flags/al.svg);
}
.fi-am {
  background-image: url(src/assets/flags/am.svg);
}
.fi-an {
  background-image: url(src/assets/flags/an.svg);
}
.fi-ao {
  background-image: url(src/assets/flags/ao.svg);
}
.fi-aq {
  background-image: url(src/assets/flags/aq.svg);
}
.fi-ar {
  background-image: url(src/assets/flags/ar.svg);
}
.fi-as {
  background-image: url(src/assets/flags/as.svg);
}
.fi-at {
  background-image: url(src/assets/flags/at.svg);
}
.fi-au {
  background-image: url(src/assets/flags/au.svg);
}
.fi-aw {
  background-image: url(src/assets/flags/aw.svg);
}
.fi-ax {
  background-image: url(src/assets/flags/ax.svg);
}
.fi-az {
  background-image: url(src/assets/flags/az.svg);
}
.fi-ba {
  background-image: url(src/assets/flags/ba.svg);
}
.fi-bb {
  background-image: url(src/assets/flags/bb.svg);
}
.fi-bd {
  background-image: url(src/assets/flags/bd.svg);
}
.fi-be {
  background-image: url(src/assets/flags/be.svg);
}
.fi-bf {
  background-image: url(src/assets/flags/bf.svg);
}
.fi-bg {
  background-image: url(src/assets/flags/bg.svg);
}
.fi-bh {
  background-image: url(src/assets/flags/bh.svg);
}
.fi-bi {
  background-image: url(src/assets/flags/bi.svg);
}
.fi-bj {
  background-image: url(src/assets/flags/bj.svg);
}
.fi-bl {
  background-image: url(src/assets/flags/bl.svg);
}
.fi-bm {
  background-image: url(src/assets/flags/bm.svg);
}
.fi-bn {
  background-image: url(src/assets/flags/bn.svg);
}
.fi-bo {
  background-image: url(src/assets/flags/bo.svg);
}
.fi-bq {
  background-image: url(src/assets/flags/bq.svg);
}
.fi-br {
  background-image: url(src/assets/flags/br.svg);
}
.fi-bs {
  background-image: url(src/assets/flags/bs.svg);
}
.fi-bt {
  background-image: url(src/assets/flags/bt.svg);
}
.fi-bv {
  background-image: url(src/assets/flags/bv.svg);
}
.fi-bw {
  background-image: url(src/assets/flags/bw.svg);
}
.fi-by {
  background-image: url(src/assets/flags/by.svg);
}
.fi-bz {
  background-image: url(src/assets/flags/bz.svg);
}
.fi-ca {
  background-image: url(src/assets/flags/ca.svg);
}
.fi-cc {
  background-image: url(src/assets/flags/cc.svg);
}
.fi-cd {
  background-image: url(src/assets/flags/cd.svg);
}
.fi-cf {
  background-image: url(src/assets/flags/cf.svg);
}
.fi-cg {
  background-image: url(src/assets/flags/cg.svg);
}
.fi-ch {
  background-image: url(src/assets/flags/ch.svg);
}
.fi-ci {
  background-image: url(src/assets/flags/ci.svg);
}
.fi-ck {
  background-image: url(src/assets/flags/ck.svg);
}
.fi-cl {
  background-image: url(src/assets/flags/cl.svg);
}
.fi-cm {
  background-image: url(src/assets/flags/cm.svg);
}
.fi-cn {
  background-image: url(src/assets/flags/cn.svg);
}
.fi-co {
  background-image: url(src/assets/flags/co.svg);
}
.fi-cr {
  background-image: url(src/assets/flags/cr.svg);
}
.fi-cu {
  background-image: url(src/assets/flags/cu.svg);
}
.fi-cv {
  background-image: url(src/assets/flags/cv.svg);
}
.fi-cw {
  background-image: url(src/assets/flags/cw.svg);
}
.fi-cx {
  background-image: url(src/assets/flags/cx.svg);
}
.fi-cy {
  background-image: url(src/assets/flags/cy.svg);
}
.fi-cz {
  background-image: url(src/assets/flags/cz.svg);
}
.fi-de {
  background-image: url(src/assets/flags/de.svg);
}
.fi-dj {
  background-image: url(src/assets/flags/dj.svg);
}
.fi-dk {
  background-image: url(src/assets/flags/dk.svg);
}
.fi-dm {
  background-image: url(src/assets/flags/dm.svg);
}
.fi-do {
  background-image: url(src/assets/flags/do.svg);
}
.fi-dz {
  background-image: url(src/assets/flags/dz.svg);
}
.fi-ec {
  background-image: url(src/assets/flags/ec.svg);
}
.fi-ee {
  background-image: url(src/assets/flags/ee.svg);
}
.fi-eg {
  background-image: url(src/assets/flags/eg.svg);
}
.fi-eh {
  background-image: url(src/assets/flags/eh.svg);
}
.fi-er {
  background-image: url(src/assets/flags/er.svg);
}
.fi-es {
  background-image: url(src/assets/flags/es.svg);
}
.fi-et {
  background-image: url(src/assets/flags/et.svg);
}
.fi-fi {
  background-image: url(src/assets/flags/fi.svg);
}
.fi-fj {
  background-image: url(src/assets/flags/fj.svg);
}
.fi-fk {
  background-image: url(src/assets/flags/fk.svg);
}
.fi-fm {
  background-image: url(src/assets/flags/fm.svg);
}
.fi-fo {
  background-image: url(src/assets/flags/fo.svg);
}
.fi-fr {
  background-image: url(src/assets/flags/fr.svg);
}
.fi-ga {
  background-image: url(src/assets/flags/ga.svg);
}
.fi-gb {
  background-image: url(src/assets/flags/gb.svg);
}
.fi-gd {
  background-image: url(src/assets/flags/gd.svg);
}
.fi-ge {
  background-image: url(src/assets/flags/ge.svg);
}
.fi-gf {
  background-image: url(src/assets/flags/gf.svg);
}
.fi-gg {
  background-image: url(src/assets/flags/gg.svg);
}
.fi-gh {
  background-image: url(src/assets/flags/gh.svg);
}
.fi-gi {
  background-image: url(src/assets/flags/gi.svg);
}
.fi-gl {
  background-image: url(src/assets/flags/gl.svg);
}
.fi-gm {
  background-image: url(src/assets/flags/gm.svg);
}
.fi-gn {
  background-image: url(src/assets/flags/gn.svg);
}
.fi-gp {
  background-image: url(src/assets/flags/gp.svg);
}
.fi-gq {
  background-image: url(src/assets/flags/gq.svg);
}
.fi-gr {
  background-image: url(src/assets/flags/gr.svg);
}
.fi-gs {
  background-image: url(src/assets/flags/gs.svg);
}
.fi-gt {
  background-image: url(src/assets/flags/gt.svg);
}
.fi-gu {
  background-image: url(src/assets/flags/gu.svg);
}
.fi-gw {
  background-image: url(src/assets/flags/gw.svg);
}
.fi-gy {
  background-image: url(src/assets/flags/gy.svg);
}
.fi-hk {
  background-image: url(src/assets/flags/hk.svg);
}
.fi-hm {
  background-image: url(src/assets/flags/hm.svg);
}
.fi-hn {
  background-image: url(src/assets/flags/hn.svg);
}
.fi-hr {
  background-image: url(src/assets/flags/hr.svg);
}
.fi-ht {
  background-image: url(src/assets/flags/ht.svg);
}
.fi-hu {
  background-image: url(src/assets/flags/hu.svg);
}
.fi-id {
  background-image: url(src/assets/flags/id.svg);
}
.fi-ie {
  background-image: url(src/assets/flags/ie.svg);
}
.fi-il {
  background-image: url(src/assets/flags/il.svg);
}
.fi-im {
  background-image: url(src/assets/flags/im.svg);
}
.fi-in {
  background-image: url(src/assets/flags/in.svg);
}
.fi-io {
  background-image: url(src/assets/flags/io.svg);
}
.fi-iq {
  background-image: url(src/assets/flags/iq.svg);
}
.fi-ir {
  background-image: url(src/assets/flags/ir.svg);
}
.fi-is {
  background-image: url(src/assets/flags/is.svg);
}
.fi-it {
  background-image: url(src/assets/flags/it.svg);
}
.fi-je {
  background-image: url(src/assets/flags/je.svg);
}
.fi-jm {
  background-image: url(src/assets/flags/jm.svg);
}
.fi-jo {
  background-image: url(src/assets/flags/jo.svg);
}
.fi-jp {
  background-image: url(src/assets/flags/jp.svg);
}
.fi-ke {
  background-image: url(src/assets/flags/ke.svg);
}
.fi-kg {
  background-image: url(src/assets/flags/kg.svg);
}
.fi-kh {
  background-image: url(src/assets/flags/kh.svg);
}
.fi-ki {
  background-image: url(src/assets/flags/ki.svg);
}
.fi-km {
  background-image: url(src/assets/flags/km.svg);
}
.fi-kn {
  background-image: url(src/assets/flags/kn.svg);
}
.fi-kp {
  background-image: url(src/assets/flags/kp.svg);
}
.fi-kr {
  background-image: url(src/assets/flags/kr.svg);
}
.fi-kw {
  background-image: url(src/assets/flags/kw.svg);
}
.fi-ky {
  background-image: url(src/assets/flags/ky.svg);
}
.fi-kz {
  background-image: url(src/assets/flags/kz.svg);
}
.fi-la {
  background-image: url(src/assets/flags/la.svg);
}
.fi-lb {
  background-image: url(src/assets/flags/lb.svg);
}
.fi-lc {
  background-image: url(src/assets/flags/lc.svg);
}
.fi-li {
  background-image: url(src/assets/flags/li.svg);
}
.fi-lk {
  background-image: url(src/assets/flags/lk.svg);
}
.fi-lr {
  background-image: url(src/assets/flags/lr.svg);
}
.fi-ls {
  background-image: url(src/assets/flags/ls.svg);
}
.fi-lt {
  background-image: url(src/assets/flags/lt.svg);
}
.fi-lu {
  background-image: url(src/assets/flags/lu.svg);
}
.fi-lv {
  background-image: url(src/assets/flags/lv.svg);
}
.fi-ly {
  background-image: url(src/assets/flags/ly.svg);
}
.fi-ma {
  background-image: url(src/assets/flags/ma.svg);
}
.fi-mc {
  background-image: url(src/assets/flags/mc.svg);
}
.fi-md {
  background-image: url(src/assets/flags/md.svg);
}
.fi-me {
  background-image: url(src/assets/flags/me.svg);
}
.fi-mf {
  background-image: url(src/assets/flags/mf.svg);
}
.fi-mg {
  background-image: url(src/assets/flags/mg.svg);
}
.fi-mh {
  background-image: url(src/assets/flags/mh.svg);
}
.fi-mk {
  background-image: url(src/assets/flags/mk.svg);
}
.fi-ml {
  background-image: url(src/assets/flags/ml.svg);
}
.fi-mm {
  background-image: url(src/assets/flags/mm.svg);
}
.fi-mn {
  background-image: url(src/assets/flags/mn.svg);
}
.fi-mo {
  background-image: url(src/assets/flags/mo.svg);
}
.fi-mp {
  background-image: url(src/assets/flags/mp.svg);
}
.fi-mq {
  background-image: url(src/assets/flags/mq.svg);
}
.fi-mr {
  background-image: url(src/assets/flags/mr.svg);
}
.fi-ms {
  background-image: url(src/assets/flags/ms.svg);
}
.fi-mt {
  background-image: url(src/assets/flags/mt.svg);
}
.fi-mu {
  background-image: url(src/assets/flags/mu.svg);
}
.fi-mv {
  background-image: url(src/assets/flags/mv.svg);
}
.fi-mw {
  background-image: url(src/assets/flags/mw.svg);
}
.fi-mx {
  background-image: url(src/assets/flags/mx.svg);
}
.fi-my {
  background-image: url(src/assets/flags/my.svg);
}
.fi-mz {
  background-image: url(src/assets/flags/mz.svg);
}
.fi-na {
  background-image: url(src/assets/flags/na.svg);
}
.fi-nc {
  background-image: url(src/assets/flags/nc.svg);
}
.fi-ne {
  background-image: url(src/assets/flags/ne.svg);
}
.fi-nf {
  background-image: url(src/assets/flags/nf.svg);
}
.fi-ng {
  background-image: url(src/assets/flags/ng.svg);
}
.fi-ni {
  background-image: url(src/assets/flags/ni.svg);
}
.fi-nl {
  background-image: url(src/assets/flags/nl.svg);
}
.fi-no {
  background-image: url(src/assets/flags/no.svg);
}
.fi-np {
  background-image: url(src/assets/flags/np.svg);
}
.fi-nr {
  background-image: url(src/assets/flags/nr.svg);
}
.fi-nu {
  background-image: url(src/assets/flags/nu.svg);
}
.fi-nz {
  background-image: url(src/assets/flags/nz.svg);
}
.fi-om {
  background-image: url(src/assets/flags/om.svg);
}
.fi-pa {
  background-image: url(src/assets/flags/pa.svg);
}
.fi-pe {
  background-image: url(src/assets/flags/pe.svg);
}
.fi-pf {
  background-image: url(src/assets/flags/pf.svg);
}
.fi-pg {
  background-image: url(src/assets/flags/pg.svg);
}
.fi-ph {
  background-image: url(src/assets/flags/ph.svg);
}
.fi-pk {
  background-image: url(src/assets/flags/pk.svg);
}
.fi-pl {
  background-image: url(src/assets/flags/pl.svg);
}
.fi-pm {
  background-image: url(src/assets/flags/pm.svg);
}
.fi-pn {
  background-image: url(src/assets/flags/pn.svg);
}
.fi-pr {
  background-image: url(src/assets/flags/pr.svg);
}
.fi-ps {
  background-image: url(src/assets/flags/ps.svg);
}
.fi-pt {
  background-image: url(src/assets/flags/pt.svg);
}
.fi-pw {
  background-image: url(src/assets/flags/pw.svg);
}
.fi-py {
  background-image: url(src/assets/flags/py.svg);
}
.fi-qa {
  background-image: url(src/assets/flags/qa.svg);
}
.fi-re {
  background-image: url(src/assets/flags/re.svg);
}
.fi-ro {
  background-image: url(src/assets/flags/ro.svg);
}
.fi-rs {
  background-image: url(src/assets/flags/rs.svg);
}
.fi-ru {
  background-image: url(src/assets/flags/ru.svg);
}
.fi-rw {
  background-image: url(src/assets/flags/rw.svg);
}
.fi-sa {
  background-image: url(src/assets/flags/sa.svg);
}
.fi-sb {
  background-image: url(src/assets/flags/sb.svg);
}
.fi-sc {
  background-image: url(src/assets/flags/sc.svg);
}
.fi-sd {
  background-image: url(src/assets/flags/sd.svg);
}
.fi-se {
  background-image: url(src/assets/flags/se.svg);
}
.fi-sg {
  background-image: url(src/assets/flags/sg.svg);
}
.fi-sh {
  background-image: url(src/assets/flags/sh.svg);
}
.fi-si {
  background-image: url(src/assets/flags/si.svg);
}
.fi-sj {
  background-image: url(src/assets/flags/sj.svg);
}
.fi-sk {
  background-image: url(src/assets/flags/sk.svg);
}
.fi-sl {
  background-image: url(src/assets/flags/sl.svg);
}
.fi-sm {
  background-image: url(src/assets/flags/sm.svg);
}
.fi-sn {
  background-image: url(src/assets/flags/sn.svg);
}
.fi-so {
  background-image: url(src/assets/flags/so.svg);
}
.fi-sr {
  background-image: url(src/assets/flags/sr.svg);
}
.fi-ss {
  background-image: url(src/assets/flags/ss.svg);
}
.fi-st {
  background-image: url(src/assets/flags/st.svg);
}
.fi-sv {
  background-image: url(src/assets/flags/sv.svg);
}
.fi-sx {
  background-image: url(src/assets/flags/sx.svg);
}
.fi-sy {
  background-image: url(src/assets/flags/sy.svg);
}
.fi-sz {
  background-image: url(src/assets/flags/sz.svg);
}
.fi-tc {
  background-image: url(src/assets/flags/tc.svg);
}
.fi-td {
  background-image: url(src/assets/flags/td.svg);
}
.fi-tf {
  background-image: url(src/assets/flags/tf.svg);
}
.fi-tg {
  background-image: url(src/assets/flags/tg.svg);
}
.fi-th {
  background-image: url(src/assets/flags/th.svg);
}
.fi-tj {
  background-image: url(src/assets/flags/tj.svg);
}
.fi-tk {
  background-image: url(src/assets/flags/tk.svg);
}
.fi-tl {
  background-image: url(src/assets/flags/tl.svg);
}
.fi-tm {
  background-image: url(src/assets/flags/tm.svg);
}
.fi-tn {
  background-image: url(src/assets/flags/tn.svg);
}
.fi-to {
  background-image: url(src/assets/flags/to.svg);
}
.fi-tr {
  background-image: url(src/assets/flags/tr.svg);
}
.fi-tt {
  background-image: url(src/assets/flags/tt.svg);
}
.fi-tv {
  background-image: url(src/assets/flags/tv.svg);
}
.fi-tw {
  background-image: url(src/assets/flags/tw.svg);
}
.fi-tz {
  background-image: url(src/assets/flags/tz.svg);
}
.fi-ua {
  background-image: url(src/assets/flags/ua.svg);
}
.fi-ug {
  background-image: url(src/assets/flags/ug.svg);
}
.fi-um {
  background-image: url(src/assets/flags/um.svg);
}
.fi-us {
  background-image: url(src/assets/flags/us.svg);
}
.fi-uy {
  background-image: url(src/assets/flags/uy.svg);
}
.fi-uz {
  background-image: url(src/assets/flags/uz.svg);
}
.fi-va {
  background-image: url(src/assets/flags/va.svg);
}
.fi-vc {
  background-image: url(src/assets/flags/vc.svg);
}
.fi-ve {
  background-image: url(src/assets/flags/ve.svg);
}
.fi-vg {
  background-image: url(src/assets/flags/vg.svg);
}
.fi-vi {
  background-image: url(src/assets/flags/vi.svg);
}
.fi-vn {
  background-image: url(src/assets/flags/vn.svg);
}
.fi-vu {
  background-image: url(src/assets/flags/vu.svg);
}
.fi-wf {
  background-image: url(src/assets/flags/wf.svg);
}
.fi-ws {
  background-image: url(src/assets/flags/ws.svg);
}
.fi-ye {
  background-image: url(src/assets/flags/ye.svg);
}
.fi-yt {
  background-image: url(src/assets/flags/yt.svg);
}
.fi-za {
  background-image: url(src/assets/flags/za.svg);
}
.fi-zm {
  background-image: url(src/assets/flags/zm.svg);
}
.fi-zw {
  background-image: url(src/assets/flags/zw.svg);
}
.fi-ac {
  background-image: url(src/assets/flags/ac.svg);
}
.fi-eu {
  background-image: url(src/assets/flags/eu.svg);
}
.fi-ta {
  background-image: url(src/assets/flags/ta.svg);
}
.fi-xk {
  background-image: url(src/assets/flags/xk.svg);
}
