.vehiculo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.veh {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  min-width: 2em !important;
  line-height: 1em;
}
.veh:before {
  content: '\00a0';
}
.veh-caja-movil-45 {
  background-image: url(src/assets/images/veh-clases/caja-movil-45.svg);
}
.veh-caja-movil-45b {
  background-image: url(src/assets/images/veh-clases/caja-movil-45b.svg);
}
.veh-ciclomotor {
  background-image: url(src/assets/images/veh-clases/ciclomotor.svg);
}
.veh-cisterna {
  background-image: url(src/assets/images/veh-clases/cisterna.svg);
}
.veh-contenedor {
  background-image: url(src/assets/images/veh-clases/contenedor.svg);
}
.veh-dolly {
  background-image: url(src/assets/images/veh-clases/dolly.svg);
}
.veh-frigorifico {
  background-image: url(src/assets/images/veh-clases/frigorifico.svg);
}
.veh-furgon {
  background-image: url(src/assets/images/veh-clases/furgon.svg);
}
.veh-furgoneta {
  background-image: url(src/assets/images/veh-clases/furgoneta.svg);
}
.veh-gondola {
  background-image: url(src/assets/images/veh-clases/gondola.svg);
}
.veh-link {
  background-image: url(src/assets/images/veh-clases/link.svg);
}
.veh-lona {
  background-image: url(src/assets/images/veh-clases/lona.svg);
}
.veh-mafi {
  background-image: url(src/assets/images/veh-clases/mafi.svg);
}
.veh-otros {
  background-image: url(src/assets/images/veh-clases/otros.svg);
}
.veh-plataforma-lisa {
  background-image: url(src/assets/images/veh-clases/plataforma-lisa.svg);
}
.veh-portabobinas {
  background-image: url(src/assets/images/veh-clases/portabobinas.svg);
}
.veh-portacontenedor-45 {
  background-image: url(src/assets/images/veh-clases/portacontenedor-45.svg);
}
.veh-portacontenedor-45-duo {
  background-image: url(src/assets/images/veh-clases/portacontenedor-45-duo.svg);
}
.veh-portavehiculos {
  background-image: url(src/assets/images/veh-clases/portavehiculos.svg);
}
.veh-remolque {
  background-image: url(src/assets/images/veh-clases/remolque.svg);
}
.veh-externo {
  background-image: url(src/assets/images/veh-clases/matricula-externa.svg);
}
.veh-semirremolque-20 {
  background-image: url(src/assets/images/veh-clases/semirremolque-20.svg);
}
.veh-semirremolque-20-adr {
  background-image: url(src/assets/images/veh-clases/semirremolque-20-adr.svg);
}
.veh-semirremolque-25-adr {
  background-image: url(src/assets/images/veh-clases/semirremolque-25-adr.svg);
}
.veh-semirremolque-40 {
  background-image: url(src/assets/images/veh-clases/semirremolque-40.svg);
}
.veh-semirremolque-40-20 {
  background-image: url(src/assets/images/veh-clases/semirremolque-40-20.svg);
}
.veh-semirremolque-45-40-20 {
  background-image: url(src/assets/images/veh-clases/semiremolque-45-40-20.svg);
}
.veh-semirremolque-40-adr {
  background-image: url(src/assets/images/veh-clases/semirremolque-40-adr.svg);
}
.veh-semirremolque-40-adr-frigo {
  background-image: url(src/assets/images/veh-clases/semirremolque-40-adr-frigo.svg);
}
.veh-semirremolque-extensible {
  background-image: url(src/assets/images/veh-clases/semirremolque-extensible.svg);
}
.veh-tauliner {
  background-image: url(src/assets/images/veh-clases/tauliner.svg);
}
.veh-tractora {
  background-image: url(src/assets/images/veh-clases/tractora.svg);
}
.veh-turismo {
  background-image: url(src/assets/images/veh-clases/turismo.svg);
}
.veh-carretilla {
  background-image: url(src/assets/images/veh-clases/carretilla.svg);
}
.veh-rigido-dos-ejes {
  background-image: url(src/assets/images/veh-clases/rigido-2-ejes.svg);
}
.veh-rigido-tres-ejes {
  background-image: url(src/assets/images/veh-clases/rigido-3-ejes.svg);
}
