.sys-traf-tipo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-traf {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-traf:before {
  content: '\00a0';
}
.sys-traf-tipo-inspeccion-aduanera {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-inspecciones/inspeccion-aduanera.svg);
}
.sys-traf-tipo-inspeccion-fito {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-inspecciones/inspeccion-fito.svg);
}
.sys-traf-tipo-inspeccion-sanidad {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-inspecciones/inspeccion-sanidad.svg);
}
.sys-traf-tipo-inspeccion-soivre {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-inspecciones/inspeccion-soivre.svg);
}
