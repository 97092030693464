.sys-doc-req {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-doc {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-doc:before {
  content: '\00a0';
}
.sys-doc-req-exp-completo {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-cambios/asignar-expediente-completo.svg);
}
.sys-doc-req-partidas {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-cambios/tramo-actual-si-unidad-transporte.svg);
}
.sys-doc-req-puntos {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-puntos-paso.svg);
}
.sys-doc-req-merc-carga {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-carga.svg);
}
.sys-doc-req-merc-descarga {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-descarga.svg);
}
.sys-doc-req-emple {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-documentacion.svg);
}
.sys-doc-req-vehiculo {
  background-image: url(src/assets/images/iconos-sys-doc/sys-documento-requerido-documentacion-vehiculo.svg);
}
