.sys-conv-modo-cobro-emple {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-conv-emple {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-conv-emple:before {
  content: '\00a0';
}
.sys-conv-modo-cobro-emple-dietas {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_dietas.svg);
}
.sys-conv-modo-cobro-emple-euros-km {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_euros_km.svg);
}
.sys-conv-modo-cobro-emple-h-extra {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_horas_extras.svg);
}
.sys-conv-modo-cobro-emple-km-cli {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_km_cliente.svg);
}
.sys-conv-modo-cobro-emple-km-rutas {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_km_rutas.svg);
}
.sys-conv-modo-cobro-emple-noctur {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_nocturnidad.svg);
}
.sys-conv-modo-cobro-emple-radio {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_radio_viaje.svg);
}
