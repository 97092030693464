.sys-sup-estado {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-sup {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-sup:before {
  content: '\00a0';
}

.sys-sup-estado-anulado {
  background-image: url(src/assets/images/iconos-sys-sup-estado/sys_sup_estado_anulado.svg);
}
.sys-sup-estado-facturado {
  background-image: url(src/assets/images/iconos-sys-sup-estado/sys_sup_estado_facturado.svg);
}
.sys-sup-estado-pendiente {
  background-image: url(src/assets/images/iconos-sys-sup-estado/sys_sup_estado_pendiente.svg);
}
