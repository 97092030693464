.sys-traf-campo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-traf {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-traf:before {
  content: '\00a0';
}

.sys-traf-campo-precio-expediente {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-campo-aplicar-cobro-porcentaje/traf-campo-aplicar-precio-expediente.svg);
}
.sys-traf-campo-precio-recargo {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-campo-aplicar-cobro-porcentaje/traf-campo-aplicar-precio-recargo.svg);
}
.sys-traf-campo-precio-referencia {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-campo-aplicar-cobro-porcentaje/traf-campo-aplicar-precio-referencia.svg);
}
