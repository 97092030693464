.go-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.go {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.go:before {
  content: '\00a0';
}

.go-tipo-empresa {
  background-image: url(src/assets/images/iconos-goplanner/tipo-empresa.svg);
}
.go-tipo-estado {
  background-image: url(src/assets/images/iconos-goplanner/tipo-estado.svg);
}
.go-tipo-primer-conductor {
  background-image: url(src/assets/images/iconos-goplanner/tipo-primer-conductor.svg);
}
.go-tipo-segundo-conductor {
  background-image: url(src/assets/images/iconos-goplanner/tipo-segundo-conductor.svg);
}
