.sys-ped-estado {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-ped {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-ped:before {
  content: '\00a0';
}
.sys-ped-estado-anulado {
  background-image: url(src/assets/images/iconos-sys-ped/iconos-sys-pedidos-estados/ped-anulado.svg);
}
.sys-ped-estado-no-autorizado {
  background-image: url(src/assets/images/iconos-sys-ped/iconos-sys-pedidos-estados/ped-no-autorizado.svg);
}
.sys-ped-estado-pendiente {
  background-image: url(src/assets/images/iconos-sys-ped/iconos-sys-pedidos-estados/ped-pendiente.svg);
}
.sys-ped-estado-pendiente-autorizacion {
  background-image: url(src/assets/images/iconos-sys-ped/iconos-sys-pedidos-estados/ped-pendiente-autorizacion.svg);
}
.sys-ped-estado-recibido-parcial {
  background-image: url(src/assets/images/iconos-sys-ped/iconos-sys-pedidos-estados/ped-recibido-parcial.svg);
}
.sys-ped-estado-recibido-total {
  background-image: url(src/assets/images/iconos-sys-ped/iconos-sys-pedidos-estados/ped-recibido-total.svg);
}
