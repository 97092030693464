.sys-caja-tipo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.sys-caja {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-caja:before {
  content: '\00a0';
}
.sys-caja-tipo-apunte-caja {
  background-image: url(src/assets/images/iconos-sys-caja-tipo-apunte/caja.svg);
}
.sys-caja-tipo-apunte-libro {
  background-image: url(src/assets/images/iconos-sys-caja-tipo-apunte/libro.svg);
}
.sys-caja-tipo-apunte-caja-libro {
  background-image: url(src/assets/images/iconos-sys-caja-tipo-apunte/caja-libro.svg);
}
.sys-caja-tipo-apunte-sistema {
  background-image: url(src/assets/images/iconos-sys-caja-tipo-apunte/sistema.svg);
}
