.sys-traf-acarreos {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.sys-traf {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-traf:before {
  content: '\00a0';
}

.sys-traf-acarreos-acciones-carga {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-carga.svg);
}
.sys-traf-acarreos-acciones-descarga {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-entrega/tipo-entrega-carga-descarga.svg);
}
.sys-traf-acarreos-acciones-escaner {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-acarreos-acciones/escaner.svg);
}
.sys-traf-acarreos-acciones-pesado-vgm {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-acarreos-acciones/pesado-vgm.svg);
}
.sys-traf-acarreos-acciones-pif-inspeccion {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-acarreos-acciones/pif-inspeccion.svg);
}
.sys-traf-acarreos-acciones-traspaso {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-acarreos-acciones/traspaso-mercancia.svg);
}
