.sys-fact-tipo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-fact {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-fact:before {
  content: '\00a0';
}

/* iconos sys-fact-tipo-cont */
.sys-fact-tipo-comunitario {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipo-cont/comunitario.svg);
}
.sys-fact-tipo-extracomunitario {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipo-cont/extracomunitario.svg);
}
.sys-fact-tipo-trafico-nacional {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipo-cont/trafico_nacional.svg);
}
/* iconos sys-fact-estados */
.sys-fact-est-abonada {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_abonada.svg);
}
.sys-fact-est-anulada {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_anulada.svg);
}
.sys-fact-est-cobrada {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_cobrada.svg);
}
.sys-fact-est-emitida {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_emitida.svg);
}
.sys-fact-est-pagada {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_pagada.svg);
}
.sys-fact-est-pendiente {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_pendiente.svg);
}
.sys-fact-est-perdidas {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_perdidas.svg);
}
/* iconos sys-fact-resdistribucion-importes */
.sys-fact-resdist-import-km {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-redist-import/redistribuir-por-km.svg);
}
.sys-fact-resdist-import-peso {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-redist-import/redistribuir-por-peso.svg);
}
.sys-fact-resdist-import-viajes {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-redist-import/redistribuir-por-viajes.svg);
}
/* iconos sys-fact-tipos-facturacion y sys_traf_tipo_facturacion_expte */
.sys-fact-tipos-fact-viajes {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipos-facturacion/viajes.svg);
}
.sys-fact-tipos-fact-suplidos {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipos-facturacion/suplidos.svg);
}
.sys-fact-tipos-fact-recargos {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipos-facturacion/recargos.svg);
}
.sys-fact-tipos-fact-liquid-subcontrat {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipos-facturacion/liquidaciones-subcontratados.svg);
}
.sys-fact-tipos-fact-libre {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipos-facturacion/libre.svg);
}
.sys-fact-tipos-fact-notas-cargo {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipos-facturacion/notas-de-cargo.svg);
}
.sys-fact-tipos-fact-partidas {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-tipos-facturacion/partida.svg);
}
/* iconos sys-fact-clase-factura */
.sys-fact-clase-fact-general {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-clase-factura/general.svg);
}
.sys-fact-clase-fact-rectificativa {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-clase-factura/rectificativa.svg);
}
