.ent-imp-exp {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.imp-exp {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.imp-exp:before {
  content: '\00a0';
}
.imp-exp-export {
  background-image: url(src/assets/images/ent-imp-exp/export.svg);
}
.imp-exp-import {
  background-image: url(src/assets/images/ent-imp-exp/import.svg);
}
.imp-exp-posicionamiento {
  background-image: url(src/assets/images/ent-imp-exp/posicionamiento.svg);
}
.imp-exp-acarreo {
  background-image: url(src/assets/images/ent-imp-exp/acarreo.svg);
}
.imp-exp-nacional {
  background-image: url(src/assets/images/ent-imp-exp/transporte_nacional.svg);
}
.imp-exp-transito {
  background-image: url(src/assets/images/ent-imp-exp/transito.svg);
}
