.sys-mantto-modo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-mantto {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-mantto:before {
  content: '\00a0';
}
.sys-mantto-modo-control-km {
  background-image: url(src/assets/images/iconos-sys-mantto/modo-control-por-km.svg);
}
.sys-mantto-modo-control-fechas {
  background-image: url(src/assets/images/iconos-sys-mantto/modo-control-por-fechas.svg);
}
.sys-mantto-modo-control-horas {
  background-image: url(src/assets/images/iconos-sys-mantto/modo-control-por-horas.svg);
}
.sys-mantto-grupo-caract-extra-vehiculos {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-caracteristicas-extra-vehiculos.svg);
}
.sys-mantto-grupo-clase-vehiculo {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-clase-vehiculo.svg);
}
.sys-mantto-grupo-dispositivos {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-dispositivos.svg);
}
.sys-mantto-grupo-equipamientos {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-equipamientos.svg);
}
.sys-mantto-grupo-extintores {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-extintores.svg);
}
.sys-mantto-grupo-mangueras {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-mangueras.svg);
}
.sys-mantto-grupo-neumaticos {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-neumaticos.svg);
}
.sys-mantto-grupo-tacografos {
  background-image: url(src/assets/images/iconos-sys-mantto/grupo-tacografos.svg);
}
.sys-mantto-estados-pendiente {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_pendiente.svg);
}
.sys-mantto-estados-anulado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_anulado.svg);
}
.sys-mantto-estados-programado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_confirmado.svg);
}
.sys-mantto-estados-comp-parcialmente {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_parcialmente_facturado.svg);
}
.sys-mantto-estados-completado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_facturado.svg);
}
