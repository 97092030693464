.sys-traf-estado {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-traf-est {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-traf-est:before {
  content: '\00a0';
}

.sys-traf-est-confirmado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_confirmado.svg);
}
.sys-traf-est-anulado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_anulado.svg);
}
.sys-traf-est-pendiente {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_pendiente.svg);
}
.sys-traf-est-facturado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_facturado.svg);
}
.sys-traf-est-finalizado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_finalizado.svg);
}
.sys-traf-est-parc-facturado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/estado_parcialmente_facturado.svg);
}
