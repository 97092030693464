.sys-cli-tipo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.sys-cli {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-cli:before {
  content: '\00a0';
}
.sys-cli-tipo-haber-abono-cliente {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/abono-de-cliente.svg);
}
.sys-cli-tipo-haber-anticipo {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/anticipo.svg);
}
.sys-cli-tipo-haber-cobro {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/cobro.svg);
}
.sys-cli-tipo-haber-compensar {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/compensar.svg);
}
.sys-cli-tipo-haber-devolucion {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/devolucion.svg);
}
.sys-clie-tipo-haber-gestion-cobros {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/gestion-de-cobros.svg);
}
.sys-cli-tipo-haber-liquidacion-compensada {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/liquidacion-compensada.svg);
}
.sys-cli-tipo-haber-pago {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/pago.svg);
}
.sys-cli-tipo-haber-pago-cliente {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/pago-al-cliente.svg);
}
.sys-cli-tipo-haber-remanente {
  background-image: url(src/assets/images/iconos-sys-cli-tipo/remanente.svg);
}
