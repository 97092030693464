.sys-traf-tipo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-traf {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-traf:before {
  content: '\00a0';
}
.sys-traf-tipo-km-cargado-nacional {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-cargado-nacional.svg);
}
.sys-traf-tipo-km-cargado-internacional {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-cargado-internacional.svg);
}
.sys-traf-tipo-km-vacio-nacional {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-vacio-nacional.svg);
}
.sys-traf-tipo-km-vacio-internacional {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-vacio-internacional.svg);
}
.sys-traf-tipo-km-cargado {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-cargado.svg);
}
.sys-traf-tipo-km-vacio {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-vacio.svg);
}
.sys-traf-tipo-km-nacional {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-nacional.svg);
}
.sys-traf-tipo-km-internacional {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/km-internacional.svg);
}
.sys-traf-tipo-km-todos-los-km {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-km/todos-los-km.svg);
}
/* iconos sys-traf-modo-calculo-tramo-tarifa */
.sys-traf-modo-calc-tramo-tarifa-unitario {
  background-image: url(src/assets/images/iconos-sys-conv/sys-conv-modo-cobro-emple/conv_modo_cobro_emple_radio_viaje.svg);
}
.sys-traf-modo-calc-tramo-tarifa-estable {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-estados/sys-traf-modo-calculo-tramo-tarifa-establecido.svg);
}
