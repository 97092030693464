.modelo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.mo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  min-width: 2.8em !important;
  line-height: 1em;
}

.mo:before {
  content: '\00a0';
}

.mo-00 {
  background-image: url(src/assets/images/modelos/svg/00.svg);
}

.mo-01 {
  background-image: url(src/assets/images/modelos/svg/01.svg);
}

.mo-02 {
  background-image: url(src/assets/images/modelos/svg/02.svg);
}

.mo-03 {
  background-image: url(src/assets/images/modelos/svg/03.svg);
}

.mo-04 {
  background-image: url(src/assets/images/modelos/svg/04.svg);
}

.mo-05 {
  background-image: url(src/assets/images/modelos/svg/05.svg);
}

.mo-06 {
  background-image: url(src/assets/images/modelos/svg/06.svg);
}

.mo-07 {
  background-image: url(src/assets/images/modelos/svg/07.svg);
}
