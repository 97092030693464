.cliente {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.cli {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.cli:before {
  content: '\00a0';
}
.cli-pagador {
  background-image: url(src/assets/images/cli-tipos/cliente-pagador.svg);
}
.cli-carga {
  background-image: url(src/assets/images/cli-tipos/cliente-carga.svg);
}
.cli-descarga {
  background-image: url(src/assets/images/cli-tipos/cliente-descarga.svg);
}
.cli-pagador-carga-descarga {
  background-image: url(src/assets/images/cli-tipos/cliente-pagador-carga-descarga.svg);
}
