.sys-traf-tipo-trans {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-traf-tipo {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-traf-tipo:before {
  content: '\00a0';
}
.sys-traf-tipo-transporte-barco {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-embarque/tipo-embarque-barco.svg);
}
.sys-traf-tipo-transporte-ferrocarril {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-embarque/tipo-embarque-tren.svg);
}
.sys-traf-tipo-transporte-avion {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-embarque/tipo-embarque-avion.svg);
}
.sys-traf-tipo-transporte-helicoptero {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-embarque/tipo-embarque-helicoptero.svg);
}
.sys-traf-tipo-transporte-catamaran {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-tipo-embarque/tipo-embarque-catamaran.svg);
}
