.sys-liq-grupo {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-liq {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}
.sys-liq:before {
  content: '\00a0';
}

/* iconos sys-liq-grupo-horas-extra-acumuladas */
.sys-liq-grupo-horas-extra-anual {
  background-image: url(src/assets/images/iconos-sys-liq/iconos-sys-liq-grupo-horas-extra-acum/anual.svg);
}
.sys-liq-grupo-horas-extra-bianual {
  background-image: url(src/assets/images/iconos-sys-liq/iconos-sys-liq-grupo-horas-extra-acum/bianual.svg);
}
.sys-liq-grupo-horas-extra-semestral {
  background-image: url(src/assets/images/iconos-sys-liq/iconos-sys-liq-grupo-horas-extra-acum/semestral.svg);
}
/* iconos sys-liq-metodo-computo-km */
.sys-liq-metodo-computo-km-tacografo {
  background-image: url(src/assets/images/iconos-sys-liq/iconos-sys-liq-metodo-computo-km/por-km-del-tacografo.svg);
}
.sys-liq-metodo-computo-km-viaje {
  background-image: url(src/assets/images/iconos-sys-liq/iconos-sys-liq-metodo-computo-km/por-km-del-viaje.svg);
}
/* iconos sys-liq-estados son los de sys-fact-estados */
.sys-liq-estados-pendiente {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_pendiente.svg);
}
.sys-liq-estados-anulada {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_anulada.svg);
}
.sys-liq-estados-terminada {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_cobrada.svg);
}
.sys-liq-estados-facturada {
  background-image: url(src/assets/images/iconos-sys-fact/iconos-sys-fact-estados/sys_fact_estados_emitida.svg);
}
/* iconos sys-liq-incentivos-tipo-km */
.sys-liq-incentivo-km-cargados {
  background-image: url(src/assets/images/iconos-sys-liq/iconos-sys-liq-incentivos-tipo-km/incentivo-km-cargados.svg);
}
.sys-liq-incentivo-km-totales {
  background-image: url(src/assets/images/iconos-sys-liq/iconos-sys-liq-incentivos-tipo-km/incentivo-km-totales.svg);
}
