/*
 *__color_variables
 */
:root {
  --primary-50: var(--blue-50);
  --primary-100: var(--blue-100);
  --primary-200: var(--blue-200);
  --primary-300: var(--blue-300);
  --primary-400: var(--blue-400);
  --primary-500: var(--blue-500);
  --primary-600: var(--blue-600);
  --primary-700: var(--blue-700);
  --primary-800: var(--blue-800);
  --primary-900: var(--blue-900);
  --primary-color: var(--blue-500);
  --outline-color: var(--blue-200) !important;
  --fc-button-active-bg-color: var(--blue-700);
  --fc-button-active-border-color: var(--blue-700);
  --fc-button-hover-bg-color: var(--blue-600);
  --fc-button-hover-border-color: var(--blue-600);
  --fc-button-bg-color: var(--blue-500);
  --fc-button-border-color: var(--blue-500);
  color-scheme: light;
  --antiZoom: 1;
  --zoom: 1;
  --body-height: calc(100dvh / var(--zoom));
  --container-height: calc(100dvh / var(--zoom) - 4.7rem);
  --generic-wrapper-height: calc(100dvh / var(--zoom) - 9.6rem);
  // --font-family: Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
  // Segoe UI Emoji, Segoe UI Symbol;
}

/*
 *__main
 */
html,
body {
  height: 100%;
}

html {
  font-size: 12px;
  @media (min-width: 1200px) {
    font-size: 14px;
  }
  overflow: hidden;
  height: 100%;
}

.antizoom {
  zoom: var(--antiZoom);
}

body {
  zoom: var(--zoom);
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  overflow: hidden;
}

app-root {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  display: block;
}

app-root > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
/*
*__badges
*/
.badge_button_absolute {
  .p-badge {
    position: absolute;
    top: 5px;
    right: 6px;
  }
}

/*
*__botones
*/
.p-button-rounded-sm {
  width: 2rem !important;
  height: 2rem !important;
}

/*
*__avatar fit cover
*/
.p-avatar img {
  object-fit: cover;
}

/*
*__correccion z-index banderas
*/
.fi {
  position: initial;
}

/*
*__fondo alerta animación
*/
.bg-alert-anim {
  animation: alert 1.5s infinite;
  animation-direction: alternate-reverse;
}

@keyframes alert {
  0% {
    background-color: var(--red-100);
  }
  100% {
    background-color: var(--red-0);
  }
}

/*
 *__Página de detalle con ancho responsive
 */
@media (min-width: 575px) {
  .detail-page {
    .responsive-width {
      max-width: calc(100% - 19rem) !important;
    }
  }
}

/*
 *__Header highlight para las cabeceras
 */
.header_highlight {
  border-top-width: 3px !important;
  border-top-style: solid;
  border-color: var(--blue-500) !important;
}

/* Dialog */
.p-dialog .p-dialog-header {
  padding: 1rem 1.5rem !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  padding: 0.5rem;
}

/* Panel */
.p-panel .p-panel-header {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

/* En tu archivo styles.css o styles.scss */
.panel_header_blue {
  .p-panel-header {
    background-color: var(--blue-500) !important;
    color: #ffffff;
  }
  .p-panel-toggler {
    color: #ffffff;
  }
}

.p-panel {
  &.p-panel-padding-sm {
    .p-panel-content {
      padding: 0.5rem !important;
    }
  }
  &.p-panel-padding-md {
    .p-panel-content {
      padding: 1rem !important;
    }
  }
}

//Tab View Form Mutation Page
app-form-mutation-page {
  .p-tabview-scrollable .p-tabview-nav-container {
    max-width: calc(100vw - 5rem);
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    height: calc(100% + calc(var(--zoom) * 1%));
  }
}

/* Más opacos los campos disabled */
.p-dropdown.p-disabled,
.p-inputtext:disabled,
.p-inputnumber:disabled,
.p-inputwrapper .p-component:disabled {
  opacity: 1;
  background-color: var(--surface-200) !important;
}

// Tamaño del panel dropdown forzado a 34px
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  height: 34px;
  min-height: 34px;
  max-height: 34px;
}

/* Dropdown con subtitulo */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:has(.subtitle),
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:has(.subtitle) {
  padding: 0.25rem 0.75rem !important;
}

/* Tamaño del multiselect */
.p-multiselect .p-multiselect-label:not(.p-placeholder) {
  padding: 0.375rem 0.75rem !important;
}

/* Grid frozen column */
.p-element.p-frozen-column.left-3 {
  left: 1.75rem !important;
}

.p-element.p-frozen-column.left-5 {
  left: 3rem !important;
}

.p-element.p-frozen-column.left-8 {
  left: 4.75rem !important;
}

/* Ventana de filtros */
.filter-overlay-panel {
  .p-overlaypanel-content {
    padding: 0.5rem !important;
  }
}

/** Fix espaciado input calendar */
p-calendar.p-calendar-clearable .p-input-icon-right .p-calendar-clear-icon {
  top: 0.5rem;
  right: 1.5rem;
}

p-calendar.p-calendar-clearable .p-input-icon-right .p-inputtext {
  padding-right: 3.5rem;
}

/** Botones de subir y bajar número */
.p-inputnumber-buttons-stacked > input {
  border-radius: var(--border-radius) !important;
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-top: 1px solid var(--surface-300);
  border-left: 1px solid var(--surface-300);
}

.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-bottom: 1px solid var(--surface-300);
  border-left: 1px solid var(--surface-300);
}

goplanner-search-overlay
  .p-datatable
  .p-datatable-tbody
  > tr:first-child.p-highlight
  > td {
  border-top: 1px solid #bedaff;
}

/* POPOVER FULLCALENDAR */
.fc .fc-more-popover .fc-popover-body {
  max-height: 8rem;
  overflow-y: auto;
}

.fc-event .fc-event-main {
  cursor: pointer;
}

/* SCROLL */
/* Estilos globales para el scrollbar del navegador */
::-webkit-scrollbar {
  transition: width 0.2s ease-in-out;
  width: 0.65rem;
  height: 0.65rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--surface-300);
  border-radius: 5px;
  border: 0.1rem solid transparent;
  background-clip: padding-box;

  &:hover {
    background-color: var(--surface-400);
    border: 0;
  }
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--surface-100);
}

/* Estilos para Firefox */
::-moz-scrollbar {
  transition: width 0.2s ease-in-out;
  width: 0.65rem;
  height: 0.65rem;
}

::-moz-scrollbar-thumb {
  background-color: var(--surface-300);
  border-radius: 5px;
  border: 0.1rem solid transparent;
  background-clip: padding-box;

  &:hover {
    background-color: var(--surface-400);
    border: 0;
  }
}

::-moz-scrollbar-track {
  border-radius: 5px;
  background-color: var(--surface-100);
}

/* Estilos para Edge y IE */
::-ms-scrollbar {
  transition: width 0.2s ease-in-out;
  width: 0.65rem;
  height: 0.65rem;
}

::-ms-scrollbar-thumb {
  background-color: var(--surface-300);
  border-radius: 5px;
  border: 0.1rem solid transparent;
  background-clip: padding-box;

  &:hover {
    background-color: var(--surface-400);
    border: 0;
  }
}

::-ms-scrollbar-track {
  border-radius: 5px;
  background-color: var(--surface-100);
}
/* FIN SCROLL */

/* icons */
.fa-adr::before {
  content: url('./assets/images/adr.svg');
  display: inline-block;
  width: 1.5rem;
  vertical-align: middle;
}

.icon-disabled {
  filter: grayscale(100%) brightness(150%);
  -webkit-filter: grayscale(100%) brightness(150%);
  -moz-filter: grayscale(100%) brightness(150%);
  opacity: 0.3;
}

.p-toast-message-icon * {
  overflow: visible;
}
/* fin icons */

/* CABECERAS */
.fondo_header_planificaciones {
  position: relative;
  z-index: 2 !important;
  background: linear-gradient(45deg, var(--cyan-500), var(--cyan-700));
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-position: center;
    background-size: cover;
    background-image: url('/assets/images/bg/data-background.png');
    z-index: -1;
  }
}
/* FIN CABECERAS */

/* APP HEIGHTS */
// Height del body de la app
.body-height {
  height: calc(100dvh / var(--zoom)) !important;
}

// Height del body de la app sin el header
.container-height {
  height: calc(100dvh / var(--zoom) - 4.7rem) !important;
}

// Height del body de la app sin el header y el header del wrapper
.genericwrapper-height {
  height: calc(100dvh / var(--zoom) - 9.2rem) !important;
}
/* FIN APP HEIGHTS */

@media screen and (min-width: 992px) {
  /* GRID HEIGHTS */
  app-list-page app-grid-view goplanner-grid satinel-system-grid {
    .p-datatable .p-datatable-wrapper {
      max-height: calc(100dvh / var(--zoom) - 27.2rem);
    }
  }

  app-select-view
    app-list-page
    app-grid-view
    goplanner-grid
    satinel-system-grid {
    .p-datatable .p-datatable-wrapper {
      max-height: calc(100dvh / var(--zoom) - 30.5rem);
    }
  }
}

app-main-edit-page
  app-detail-edit-page
  app-list-page
  app-grid-view
  goplanner-grid
  satinel-system-grid {
  .p-datatable .p-datatable-wrapper {
    max-height: calc(100dvh / var(--zoom) - 34rem);
  }
}
/* FIN GRID HEIGHT */

/* STACK MAX WIDTH */
@media screen and (max-width: 768px) {
  .p-datatable .p-datatable-table > .p-datatable-tbody > tr > td {
    max-width: unset !important;
    flex-wrap: wrap;
  }
}

.white-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--surface-50);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
  width: inherit;
}

.grid-tooltip.p-tooltip .p-tooltip-text {
  width: inherit;
}

.white-tooltip.p-tooltip .p-tooltip-arrow {
  display: none;
}

/** fondo animado progreso */
.animacion_progreso_verde {
  background-image: repeating-linear-gradient(
    45deg,
    rgb(34, 197, 94),
    rgb(34, 197, 94) 10px,
    rgb(29, 168, 80) 10px,
    rgb(29, 168, 80) 20px
  );
  background-size: 200% 100%;
  animation: slideStripes 4s linear infinite;
  will-change: background-position;
  .text-shadow {
    text-shadow: 1px 1px 2px black;
  }
}

@keyframes slideStripes {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

/* Acordeon small */
.p-accordion {
  &.small {
    .p-accordion-header-link {
      padding: 0.5rem 1rem;
    }
    .p-accordion-content {
      padding: 0.5rem;
      background-color: var(--blue-200);
    }
  }

  &.small_no_bg {
    .p-accordion-header-link {
      padding: 0.5rem 1rem;
    }
    .p-accordion-content {
      padding: 0.5rem;
    }
  }
}

.button-set {
  .p-button,
  .p-button .p-button-icon {
    border-radius: 0;
  }

  .p-togglebutton.p-button {
    padding: 0;
    height: 100%;
  }

  .p-togglebutton.p-button .p-button-icon {
    padding: 0.62rem 0;
    height: 100%;
    width: 100%;
  }

  p-togglebutton:first-child {
    .p-button {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .p-togglebutton.p-button.p-highlight > span {
    background-color: var(--primary-500) !important;
    color: var(--surface-100) !important;
  }

  p-togglebutton:last-child {
    .p-button {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

/* documentos */
.light {
  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    background-color: var(--green-200);
  }
}
.dark {
  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    background-color: var(--blue-200);
  }
}

/* tooltip white */
.tooltip-white .p-tooltip-text {
  background-color: var(--surface-50);
  color: var(--text-color);
  border: 1px solid var(--surface-border);
}
.tooltip-large {
  min-width: 30rem;
}

.tooltip-white .p-tooltip-arrow {
  display: none !important;
}
