.sys-empl-situacion {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-empl {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-empl:before {
  content: '\00a0';
}
.sys-empl-situacion-alta {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/sys_empl_situacion_alta.svg);
}
.sys-empl-situacion-alta-restring {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/sys_empl_situacion_alta_restring.svg);
}
.sys-empl-situacion-baja {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/sys_empl_situacion_baja.svg);
}
.sys-empl-situacion-baja-def {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/sys_empl_situacion_baja_def.svg);
}
.sys-empl-situacion-pend-alta {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/sys_empl_situacion_pend_alta.svg);
}

.sys-empl-metodo-asig-final-exp {
  background-image: url(src/assets/images/iconos-sys-empre-metodo-asignacion-vacios/asignar-final-expediente-en-curso.svg);
}
.sys-empl-metodo-asig-principio-sig-exp {
  background-image: url(src/assets/images/iconos-sys-empre-metodo-asignacion-vacios/asignar-principio-siguiente-expediente.svg);
}
/* estados conduccion */
.sys-empl-estados-conduccion-conduccion {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/iconos-sys-empl-estados-conduccion/estados-conduccion-conduccion.svg);
}
.sys-empl-estados-conduccion-descanso {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/iconos-sys-empl-estados-conduccion/estados-conduccion-descanso.svg);
}
.sys-empl-estados-conduccion-disponibili {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/iconos-sys-empl-estados-conduccion/estados-conduccion-disponibilidad.svg);
}
.sys-empl-estados-conduccion-otros-trab {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/iconos-sys-empl-estados-conduccion/estados-conduccion-otros-trabajos.svg);
}
.sys-empl-estados-conduccion-sin-asign {
  background-image: url(src/assets/images/iconos-sys-empl-situacion/iconos-sys-empl-estados-conduccion/estados-conduccion-sin-asignar.svg);
}
