.sys-veh-situaciones {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-veh {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-veh:before {
  content: '\00a0';
}
.sys-veh-situaciones-taller {
  background-image: url(src/assets/images/iconos-sys-veh-situaciones/taller.svg);
}
.sys-veh-situaciones-acarreos {
  background-image: url(src/assets/images/ent-imp-exp/acarreo.svg);
}
.sys-veh-situaciones-no-operativo {
  background-image: url(src/assets/images/iconos-sys-veh-situaciones/no_operativo.svg);
}
.sys-veh-situaciones-baja {
  background-image: url(src/assets/images/iconos-sys-veh-situaciones/baja.svg);
}
.sys-veh-situaciones-en-ruta {
  background-image: url(src/assets/images/iconos-sys-veh-situaciones/en_ruta.svg);
}
.sys-veh-situaciones-averiado {
  background-image: url(src/assets/images/iconos-sys-veh-situaciones/averiado.svg);
}
.sys-veh-situaciones-disponible {
  background-image: url(src/assets/images/iconos-sys-veh-situaciones/disponible.svg);
}
.sys-veh-situaciones-transferido {
  background-image: url(src/assets/images/iconos-sys-veh-situaciones/transferido.svg);
}
