.sys-traf-eventos {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-eventos {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-eventos:before {
  content: '\00a0';
}

.sys-traf-eventos-app-averia {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/averia.svg);
}
.sys-traf-eventos-app-cambio-trans {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/cambio_unidad_transporte.svg);
}
.sys-traf-eventos-app-desembarque {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/desembarque.svg);
}
.sys-traf-eventos-app-entrada-parking {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/entrada_parking.svg);
}
.sys-traf-eventos-app-palets-almacen {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/entrega_recogida_palets_almacen.svg);
}
.sys-traf-eventos-app-palets-cliente {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/entrega_recogida_palets_cliente.svg);
}
.sys-traf-eventos-app-repostaje {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/repostaje.svg);
}
.sys-traf-eventos-app-retraso {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/retraso.svg);
}
.sys-traf-eventos-salida-parking {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/salida_parking.svg);
}
.sys-traf-eventos-app-siniestro {
  background-image: url(src/assets/images/iconos-sys-traf/sys-traf-eventos-app-tipo/siniestro.svg);
}
