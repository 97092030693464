.sys-alb-estado {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-alb {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-alb:before {
  content: '\00a0';
}
.sys-alb-estado-pendiente {
  background-image: url(src/assets/images/iconos-sys-albaranes-estados/pendiente.svg);
}
.sys-alb-estado-anulado {
  background-image: url(src/assets/images/iconos-sys-albaranes-estados/anulado.svg);
}
.sys-alb-estado-recibido-parcial {
  background-image: url(src/assets/images/iconos-sys-albaranes-estados/recibido-parcial.svg);
}
.sys-alb-estado-recibido-total {
  background-image: url(src/assets/images/iconos-sys-albaranes-estados/recibido-total.svg);
}
