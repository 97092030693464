.sys-tipo-servicio {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.sys-tipo {
  width: 2rem;
  min-width: 2rem;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
}

.sys-tipo:before {
  content: '\00a0';
}
.sys-tipo-servicio-traspaso-definitivo {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-servicios/traspaso-definitivo.svg);
}
.sys-tipo-servicio-traspaso-pruebas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-servicios/traspaso-pruebas.svg);
}

.sys-tipo-contador-expedientes {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/expedientes.svg);
}
.sys-tipo-contador-ordenes-transporte {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/ordenes-de-transporte.svg);
}
.sys-tipo-contador-sscc {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/sscc.svg);
}
.sys-tipo-contador-numero-expedicion {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/numero-de-expedicion.svg);
}
.sys-tipo-contador-numero-incidencia {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/numero-de-incidencia.svg);
}
.sys-tipo-contador-numero-pos-vacio {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/numero-de-posicionamiento-vacio.svg);
}
.sys-tipo-contador-facturas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/factura-autofactura.svg);
}
.sys-tipo-contador-notas-cargo {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/notas-cargo.svg);
}
.sys-tipo-contador-ofertas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/ofertas.svg);
}
.sys-tipo-contador-partidas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/partidas.svg);
}
.sys-tipo-contador-autofacturas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/factura-autofactura.svg);
}
.sys-tipo-contador-liq-subcontratados {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/liquidacion-subcontratados.svg);
}
.sys-tipo-contador-suplidos {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/contador-suplidos.svg);
}
.sys-tipo-contador-liquidaciones {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/liquidaciones.svg);
}
.sys-tipo-contador-direcciones {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/direcciones.svg);
}
.sys-tipo-contador-num-viaje-exp {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/numero-viaje-expedicion.svg);
}
.sys-tipo-contador-mantenimientos {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/mantenimientos.svg);
}
.sys-tipo-contador-partidas-reservas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/partidas-reservas.svg);
}
.sys-tipo-contador-planes-mantto {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/planes-mantenimiento.svg);
}
.sys-tipo-contador-pedidos {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/pedidos.svg);
}
.sys-tipo-contador-siniestro-merc {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/siniestros-de-mercancia.svg);
}
.sys-tipo-contador-siniestro-carretera {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/siniestros-de-carretera.svg);
}
.sys-tipo-contador-facturas-proveedores {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/facturas-proveedores.svg);
}
.sys-tipo-contador-reservas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/reservas.svg);
}
.sys-tipo-contador-recargos {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/recargo.svg);
}
.sys-tipo-contador-gastos {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/gastos.svg);
}
.sys-tipo-contador-codigos-reparaciones {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/codigos-reparaciones.svg);
}
.sys-tipo-contador-multas {
  background-image: url(src/assets/images/iconos-sys-tipo/iconos-sys-tipo-contador-elemento/multas.svg);
}
